.main-slider {
  position: relative;
  background-image: url("../../../shared/assets/truck-01.jpeg");
  background-color: rgba(0, 0, 0, 0.6);
  background-blend-mode: multiply;
  background-size: cover;
  width: 100%;
  height: 100vh;
  color: white;
  text-align: center;
}

.main-slider h1 {
  font-size: 4rem;
  font-weight: bold;
  padding-bottom: 20px;
}

.main-slider h3 {
  font-size: 1.8rem;
  letter-spacing: 2px;
  padding-bottom: 20px;
}

@media screen and (max-width: 768px) {
  .main-slider h1 {
    font-size: 3rem;
  }
}

@media screen and (max-width: 460px) {
  .main-slider h1 {
    font-size: 2rem;
  }

  .main-slider h3 {
    font-size: 1.2rem;
  }
}
