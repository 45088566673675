.postcode-input-wrapper {
  position: relative;
  margin: 0 10%;
  width: 100%;
}

.find-btn {
  position: absolute;
  top: 4px;
  right: 4.5px;
  width: 35px;
  height: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #bd0000;
  color: white;
  border-radius: 100%;
  font-weight: bold;
  padding: 0;
}

.find-btn:hover {
  color: white;
}

.post-input {
  font-size: 1.1rem;
  padding: 8px 20px;
  /* border: 1px solid #bd0000; */
  border: 0;
  box-shadow: 1px 2px 10px 1px rgba(0, 0, 0, 0.144);
  border-radius: 2rem;
  background-color: white;
}

.post-input:focus {
  outline: none;
}

.post-text {
  color: rgb(94, 94, 94);
  font-size: 20px;
  margin-bottom: 0;
  margin-top: 0;
}

.code-reject {
  color: rgb(94, 94, 94);
  font-size: 20px;
  text-align: justify;
}
