.product-card {
  border-radius: 15px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.1), 0 3px 10px 0 rgba(0, 0, 0, 0.1);
  background-color: white;
}

.product-card .product-card-text {
  color: #9a9a9a;
  padding: 18px;
  font-size: 10px;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border-bottom-left-radius: 15px;
  border-bottom-right-radius: 15px;
}

.product-card.product .product-card-text {
  background-color: #ebf0f1;
}

.product-card.product .product-card-text h4 {
  font-size: 1.1rem;
  color: rgb(59, 59, 59);
}

.product-card.service .product-card-text {
  height: 185px;
}

.product-card-text a {
  margin-bottom: 5px;
}

.product-card-text h4 {
  font-weight: 700;
}

.product-card-text p {
  font-size: 0.8rem;
}

.product-icon,
.product-price {
  background-color: #bd0000;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  right: 30px;
  transition: 500ms;
  color: white;
}

.product-icon {
  top: -25px;
  width: 45px;
  height: 45px;
}

.product-price {
  top: -20px;
}

.product-price p {
  font-size: 18px;
  font-weight: bold;
  padding: 2px 10px;
  margin-bottom: 0px;
}

.product-icon,
.product-price {
  fill: white;
  transition: 500ms;
}

.product-card:hover .product-icon
.product-card:hover .product-price {
  fill: var(--red-color);
  transition: 500ms;
}

.product-card:hover .product-price-text {
  color: var(--red-color);
}

.product-card:hover .product-logo,
.product-card:hover .product-price {
  transition: 500ms;
  transform: translate(0px, -10px);
  background-color: white;
}

.product-card-section .video video {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.product-card.product .product-card-image {
  height: 260px;
}

.product-card.service .product-card-image {
  height: 180px;
}

.product-card-image {
  width: 100%;
  object-fit: cover;
  object-position: top;
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
}
