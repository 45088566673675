.custom-table-head {
  text-align: center;
  list-style: none;
  margin: 0;
  color: var(--info-color);
  font-size: 14px;
  font-weight: 700;
  background-color: #ffffff;
  display: flex;
  align-items: flex-end;
  justify-content: space-around;
  width: 100%;
  padding: 0px 20px 0px 20px;
}

.custom-table-head li {
  border-bottom: 0.1rem solid #d9dee4;
  padding-bottom: 10px;
}


.custom-table-head li:last-child {
  text-align: right;
}

.custom-table-body-ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

/* .custom-table-body-ul li .anchor-link p,
.custom-table-head li {
  min-width: 100px;
} */

/* .custom-table-body-ul li .anchor-link p:last-child, */
.custom-table-head p:last-child {
  text-align: right;
}

.custom-table-body-ul li .anchor-link p,
.custom-table-body-ul li .anchor-link p:hover {
  text-decoration: none;
  color: black;
  margin: 0;
  opacity: 0.8;
  text-align: center;
}

.custom-table-body-ul li .anchor-link {
  display: flex;
  align-items: center;
  justify-content: space-around;
  padding: 10px 0px;
  margin: 0px 20px;
  border-bottom: 0.1rem solid #d9dee4;
  text-decoration: none;
}

.custom-table-body-ul li {
  text-align: initial;
  transition: 500ms;
  background-color: #ebeef3;
  display: inline-block;
  min-width: 100%;
}

.custom-table-body-ul li:nth-child(even) {
  background-color: #f7f8fa;
}

.custom-table-body-ul li:hover {
  background-color: #e6eaee;
  transition: 500ms;
}

.custom-table-responsive {
  overflow-x: auto;
}

.custom-table-responsive .navLink {
  text-decoration: none;
}

.no-table-data {
  display: inline-block;
  min-width: 100%;
  font-size: 14px;
  background-color: #ebeef3;
  letter-spacing: 3px;
  color: #757575;
}

.filters-btns {
  display: flex;
  align-items: center;
}

.filters-btns button:focus {
  box-shadow: none;
}

.status_icon span span {
  font-size: 15px;
}

.filter-dropdown {
  padding: 3px 10px;
  border-radius: 5px;
  margin-left: 10px;
}

.filter-cb-wrapper {
  display: block;
  position: relative;
  padding-left: 25px;
  margin-left: 10px;
  cursor: pointer;
  font-size: 13px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Hide the browser's default radio button */
.filter-cb-wrapper input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

/* Create a custom radio button */
.filter-cb-wrapper .checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 17px;
  width: 17px;
  background-color: #fbfbfb;
  border-radius: 50%;
}

/* On mouse-over, add a grey background color */
.filter-cb-wrapper:hover input ~ .checkmark {
  background-color: #ccc;
}

/* When the radio button is checked, add a blue background */
.filter-cb-wrapper input:checked ~ .checkmark {
  background-color: #0088a0;
}

/* Create the indicator (the dot/circle - hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the indicator (dot/circle) when checked */
.filter-cb-wrapper input:checked ~ .checkmark:after {
  display: block;
}

/* Style the indicator (dot/circle) */
.filter-cb-wrapper .checkmark:after {
  top: 0.27rem;
  left: 0.3rem;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background: white;
}

.filter-cb-single-section {
  background-color: #e6ebf0;
  padding: 8px 20px 8px 10px;
  border-radius: 2rem;
}

.search-filter {
  padding: 5px 15px;
  margin-left: 10px;
  margin-top: 3px;
  font-size: 13px;
  border-radius: 2rem;
  border: none;
  border: 1px solid var(--info-color);
}

.search-filter:focus {
  outline: none;
}

.search-filter.date {
  max-width: 180px;
}

.table-cells{
  min-width: 150px;
}