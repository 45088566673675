.service-banner-section {
  background-image: url("../../../shared/assets/s6.png");
  background-size: cover;
  background-position: center center;
  background-color: rgb(58, 58, 58);
  background-blend-mode: multiply;
  color: #fff;
  text-align: center;
  padding-top: 60px;
  padding-bottom: 60px;
}
