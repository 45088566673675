.sidebar-section {
  list-style: none;
  margin: 80px 15px 15px 15px;
  padding: 0;
  background-color: rgb(255, 255, 255);
  box-shadow: -5px 4px 6px #d8d8d8;
  border-radius: 5px;
  border: 1px solid rgb(197, 197, 197);
}

.sidebar-section li:last-of-type {
  border: none;
}

.sidebar-section li {
  display: flex;
  flex-direction: column;
  text-align: center;
  align-items: center;
  border-bottom: 1px solid rgb(216 216 216);
  padding: 10px 0px;
}

.sidebar-section li a {
  color: black;
  text-decoration: none;
  width: 100%;
  border: 4px solid transparent;
  transition: 500ms;
  padding: 0px 10px;
}

.sidebar-section li a p {
  font-size: 0.75rem;
  margin: 0;
  padding-top: 5px;
}

.sidebar-section li button {
  background-color: transparent;
  border: none;
}

.sidebar-section li a:hover,
.sidebar-section li button:hover {
  color: var(--green-color);
  transition: 500ms;
}

.sidebar-section li a.active {
  color: var(--green-color);
  border-left: 4px solid var(--green-color);
  transition: 500ms;
}