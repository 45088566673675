.timer-modal {
  padding: 30px 20px 40px;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.timer-modal i path,
.timer-modal i svg {
  fill: var(--info-color);
}

.timer-modal p {
  font-size: 30px;
  margin-bottom: 0px;
}

.timer-modal small {
  font-size: 16px;
  color: rgb(112, 112, 112);
}

.close-btn {
  background-color: transparent;
  border: 0;
  font-size: 25px;
  outline: none;
  position: absolute;
  top: 8px;
  right: 15px;
  transition: 500ms;
  padding: 10px;
}

.close-btn:hover {
  color: var(--info-color);
  transition: 500ms;
}

.close-btn:focus {
  outline: none;
}

.progress-bar {
  position: relative;
  width: 90%;
  display: block;
  background-color: transparent;
  margin: 10px 0px;
}

.base-bar,
.color-bar {
  height: 7px;
  border: 0;
  border-radius: 1rem;
}

.base-bar {
  background-color: rgb(220, 228, 231);
  margin: 0 auto;
  width: 80%;
}

.color-bar {
  background-color: var(--info-color);
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  text-align: center;
  margin: 0 auto;
  animation-timing-function: ease-in;
  opacity: 0;
}

.color-bar.half {
  animation: progress 0.5s 3;
}

.color-bar.one {
  animation: progress 1s 3;
}

.color-bar.two {
  animation: progress 1.5s 3;
}

.color-bar.three {
  animation: progress 2s 3;
}

.color-bar.four {
  animation: progress 2.5s 3;
}

.color-bar.five {
  animation: progress 3s 3;
}

@keyframes progress {
  from {
    width: 0%;
    opacity: 1;
  }
  to {
    width: 80%;
    opacity: 1;
  }
}
