.postcode-search-section {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: baseline;
}

.postcode-search-section .custom-input-div {
  width: 100%;
}

.postcode-list {
  position: absolute;
  top: 60px;
  z-index: 1005;
  display: flex;
  flex-direction: column;
  max-height: 170px;
  overflow-y: auto;
  box-shadow: 2px 4px 10px 0px rgba(0, 0, 0, 0.767);
}

.postcode-list button {
  width: 100%;
  padding: 5px 10px;
  border: 0;
  text-align: left;
  border-bottom: 1px solid rgb(167, 167, 167);
  display: flex;
  flex-direction: column;
}

.postcode-list button:hover {
  background-color: rgb(199, 199, 199);
}
