.tooltip-status-div {
  position: relative;
}

.tooltip-status-text {
  position: absolute;
  right: 0;
  z-index: 10;
  transform: translate(0px, -25px);
  background-color: rgba(30, 36, 43, 0.795) !important;
  color: white !important;
  padding: 2px 10px;
  border-radius: 3px;
  text-align: left;
  font-size: 14px;
  font-weight: 500 !important;
  width: 120px;
  visibility: hidden;
  transition: 100ms;
}

.tooltip-status-div:hover > .tooltip-status-text {
  transition: 500ms;
  visibility: visible;
  opacity: 1;
}

.status-text {
  font-size: 0.9rem;
  padding-right: 5px;
  padding-top: 7px;
}