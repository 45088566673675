.secondary-slider.ABOUT_US {
  background-image: url("../../../shared/assets/AboutSlide.png");
}

.secondary-slider.SERVICES {
  background-image: url("../../../shared/assets/ServicesSlide.png");
}

.secondary-slider.MARKETPLACE {
  background-image: url("../../../shared/assets/truck-03.jpeg");
}

.secondary-slider.TESTIMONIALS {
  background-image: url("../../../shared/assets/Clients.png");
}

.secondary-slider.FAQS {
  background-image: url("../../../shared/assets/FAQs.jpg");
}

.secondary-slider.CONTACT {
  background-image: url("../../../shared/assets/FAQs.jpg");
}

.secondary-slider.STEPS {
  background-image: url("../../../shared/assets/ServicesSlide.png");
}

.secondary-slider.BY_SEA {
  background-image: url("../../../shared/assets/s1.jpg");
}

.secondary-slider.BY_AIR {
  background-image: url("../../../shared/assets/s2.jpg");
}

.secondary-slider.ONLINE_SHOPPING {
  background-image: url("../../../shared/assets/s3.jpg");
}

.secondary-slider.EXCESS_BAGGAGE {
  background-image: url("../../../shared/assets/s4.jpg");
}

.secondary-slider.COLLECTION_SERVICES {
  background-image: url("../../../shared/assets/s5.jpg");
}

.secondary-slider.SEARCH_AREA {
  background-image: url("../../../shared/assets/search-area.jpg");
}

.secondary-slider.default {
  background-image: url("../../../shared/assets/transportation.png");
}

.secondary-slider {
  position: relative;
  background-color: rgba(0, 0, 0, 0.705);
  background-blend-mode: multiply;
  background-position: center center;
  background-size: cover;
  width: 100%;
  height: 50vh;
  color: white;
  text-align: center;
}

.secondary-slider h1 {
  font-size: 3rem;
  font-weight: bold;
  padding-bottom: 20px;
  letter-spacing: 2px;
  margin: 0;
}

@media screen and (max-width: 768px) {
  .secondary-slider h1 {
    font-size: 3rem;
  }
}

@media screen and (max-width: 460px) {
  .secondary-slider h1 {
    font-size: 2rem;
  }
}
