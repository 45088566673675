.about-stats-section {
    background-image: url('../../../shared/assets/c4.png');
    background-size: cover;
    background-position: center center;
    padding-top: 60px;
    padding-bottom: 60px;
}

.about-stats-section .col-md-3{
    margin: 10px 0px;
}

.about-stats-section .stat-title {
    font-weight: bold;
    padding-bottom: 10px;
    border-bottom: 1.5px solid rgba(0, 0, 0, 0.3);
}