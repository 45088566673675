.shipment-record-table-head {
  color: var(--info-color);
  font-size: 14px;
  font-weight: 700;
  background-color: #ffffff;
  display: flex;
  align-items: flex-end;
  padding: 0px 20px 0px 20px;
}

.shipment-record-table-head div {
  border-bottom: 0.1rem solid #d9dee4;
  padding-bottom: 10px;
}

.shipment-record-table-head div:last-child {
  padding-right: 10px;
}

.list-body-ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

.list-body-ul li a p,
.shipment-record-table-head div {
  min-width: 150px;
}

.list-body-ul li a p:last-child {
  padding-right: 10px;
}

.list-body-ul li a p,
.list-body-ul li a p:hover {
  text-decoration: none;
  color: black;
  margin: 0;
  opacity: 0.8;
}

.list-body-ul li a {
  display: flex;
  align-items: center;
  padding: 10px 0px;
  margin: 0px 20px;
  border-bottom: 0.1rem solid #d9dee4;
  text-decoration: none;
}

.list-body-ul li {
  transition: 500ms;
  background-color: #ebeef3;
  display: inline-block;
  min-width: 100%;
}

.list-body-ul li:nth-child(even) {
  background-color: #f7f8fa;
}

.list-body-ul li:hover {
  background-color: #e6eaee;
  transition: 500ms;
}

.shipment-table-responsive {
  overflow-x: auto;
}

.shipment-table-responsive .navLink {
  text-decoration: none;
}

.btn-view {
  color: white;
}

.btn-view:hover {
  color: white;
  text-decoration: none;
}
