.collection-update-secion {
  padding-top: 50px;
  padding-bottom: 60px;
  background-color: white;
}

.collection-update-secion h1 {
  font-weight: 900;
  color: var(--red-color);
  text-align: center;
  padding-bottom: 30px;
}

.collection-card {
  padding: 30px;
  min-height: 300px;
  border-radius: 1rem;
  box-shadow: 2px 2px 20px rgba(0, 0, 0, 0.2);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin: 10px 0px;
  background-color: white;
}

.collection-card img {
  height: 80px;
  padding-bottom: 20px;
}

.update-icon {
  padding-bottom: 20px;
  transition: 500ms;
  fill: rgb(168, 168, 168);
}

.collection-card h5 {
  font-size: 1.2rem;
  font-weight: 700;
}

.collection-card p {
  margin: 0;
}

.collection-card h5 span {
  color: var(--red-color);
}

.collection-card:hover .update-icon {
  fill: var(--red-color);
  transition: 500ms;
}