.newsletter-section {
  background-color: rgb(234, 239, 243);
  padding-bottom: 60px;
  padding-top: 30px;
}

.search-info {
  background-image: url("../../../shared/assets/banner.png");
  background-size: cover;
  background-color: rgba(0, 0, 0, 0.6);
  background-blend-mode: multiply;
  height: 400px;
  padding: 50px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  border-top-left-radius: 2rem;
  border-bottom-left-radius: 2rem;
  color: white;
}

.search-info h1 {
  font-size: 3rem;
  font-weight: 900;
  letter-spacing: 1px;
}

.search-info p {
  font-size: 1.2rem;
  text-align: justify;
}

.search-info input {
  width: 100%;
  border-radius: 5rem;
  padding: 10px 20px;
  border: none;
}

.newsletter-section .video video {
  width: 100%;
  height: 400px;
  object-fit: cover;
  border-top-right-radius: 2rem;
  border-bottom-right-radius: 2rem;
}

@media screen and (max-width: 768px) {
  .search-info {
    border-top-left-radius: 2rem;
    border-top-right-radius: 2rem;
    border-bottom-left-radius: 0rem;
  }

  .search-info h1 {
    font-size: 2rem;
    font-weight: 900;
    letter-spacing: 1px;
  }

  .newsletter-section .video video {
    border-top-right-radius: 0rem;
    border-bottom-left-radius: 2rem;
    border-bottom-left-radius: 2rem;
  }
}
