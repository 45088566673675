.notification-icon {
  position: relative;
}

.notification-icon path,
.notification-icon svg {
  color: var(--info-color);
  transition: 500ms;
}

.notification-icon path:hover,
.notification-icon svg:hover {
  color: rgb(201, 201, 201);
  transition: 500ms;
}

.notification-icon-text {
  position: absolute;
  transform: translate(-10px, -10px);
  background-color: red;
  width: 20px;
  height: 20px;
  font-size: 12px;
  color: white;
  font-weight: 600;
  border-radius: 100%;
}

.notification-icon.hover > .notification-icon path,
.notification-icon.hover > .notification-icon svg {
  color: rgb(116, 116, 116);
}

.dropdown-menu {
  width: 250px;
}

.dropdown-menu a {
  display: flex;
  align-items: center;
  justify-content: space-between;
  /* flex-direction: column; */
  padding: 6px 14px 10px;
  margin: 0px 6px;
  text-decoration: none;
  color: rgb(133, 133, 133);
  font-weight: 700;
  border-radius: 3px;
}

/* .dropdown-toggle:after {
  content: none;
} */

.notification-badge {
  background-color: var(--info-color);
  color: white;
  width: 30px;
  text-align: center;
  border-radius: 5px;
  font-size: 15px;
}

.dropdown-menu a span {
  font-size: 12px;
  font-weight: 500;
}

.dropdown-menu a:hover {
  background-color: #dee4eb;
  color: black;
}

.dropdown-menu .user-info p {
  padding: 0px 20px;
  letter-spacing: 1px;
  word-wrap: break-word;
  font-size: 14px;
  margin: 0;
}

.dropdown-menu .user-info {
  background-color: #eef1f5;
  padding: 10px 0px;
}

.dropdown-menu .user-info p:first-child {
  letter-spacing: 1px;
  font-size: 18px;
  color: var(--info-color);
}

.profile.dropdown-menu {
  padding: 0px;
}

.profile.dropdown-menu a {
  background-color: white;
  margin: 0;
  padding: 10px 20px 12px 20px;
  cursor: pointer;
  border-bottom: 1px solid rgb(206, 206, 206);
  border-radius: 0px;
  transition: 500ms;
}

.profile.dropdown-menu a:hover {
  background-color: #e8ecf0;
  transition: 500ms;
}

.profile.dropdown-menu a div {
  display: flex;
  align-items: center;
}

.profile.dropdown-menu a p {
  padding-left: 5px;
}

.dropdown button:focus {
  outline: none;
  box-shadow: none;
}

.profile-dropdown {
  display: flex;
  align-items: center;
}

.profile-picture {
  vertical-align: middle;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  border: var(--info-color) 1px solid;
  object-fit: cover;
}
.dropdown-profile-name {
  color: #596672;
  margin: 0;
}
