.mobile-nav-section.unactive {
  height: 0;
  transition: 500ms;
}

.mobile-nav-section {
  background-color: rgb(0, 0, 0);
  position: absolute;
  z-index: 100;
  width: 100%;
  height: 100vh;
  transition: 500ms;
  color: white;
}

.mobile-nav-inner {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.mobile-nav-section.unactive .mobile-nav-inner {
  display: none;
}

.mobile-navlinks {
  list-style: none;
  text-align: left;
  margin: 0;
  padding: 0;
  padding-bottom: 10px;
}

.mobile-navlinks a {
  color: white;
  text-decoration: none;
  font-size: 0.9rem;
  letter-spacing: 1px;
  font-weight: 500;
}

.mobile-navlinks a.active {
  color: var(--red-color);
}


.mobile-navlinks a:hover {
  color: var(--red-color);
}

.mobile-navlinks li {
  padding: 10px 10px;
  margin-left: 20px;
  margin-right: 20px;
}

.mobile-navlinks li:not(:last-of-type) {
  border-bottom: 1.5px solid rgb(24, 24, 24);
}

.mobile-login-btn {
  padding: 0;
  margin-top: 10px;
}

.mobile-login-btn a {
  background-color: white;
  border-radius: 5px;
  color: black;
  padding: 10px 20px;
  text-transform: uppercase;
  font-size: 0.8rem;
  letter-spacing: 1px;
  font-weight: 500;
}

.nav-contact-info {
  width: 100%;
  background-color: rgb(20, 20, 20);
  text-align: left;
  padding: 20px 30px;
}

.nav-contact-info h2 {
  font-size: 1.5rem;
  padding-bottom: 1rem;
  color: rgb(71, 71, 71);
}

.nav-contact-info p:first-of-type {
  padding-top: 15px;
  border-top: 1px solid rgb(53, 53, 53);
}

.nav-contact-info p:last-of-type {
  padding: 10px 0px;
}

.nav-contact-info p {
  font-size: 0.9rem;
}

.nav-contact-info a {
  color: white;
  text-decoration: none;
}

.nav-social-icons {
  margin-top: 20px;
  display: flex;
  align-items: baseline;
}

.nav-social-icons a {
  margin-right: 10px;
  height: 50px;
  width: 50px;
  background-color: black;
  color: white;
}

.nav-social-icons a:hover {
  color: var(--red-color);
}

.nav-social-icons a svg {
  transform: translate(12px, 10px);
}
