.tracking-search-section {
  margin-top: 15px;
  background-image: url("../../../shared/assets/tracking-01.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center bottom;
  width: 100%;
  height: 320px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  padding: 10px 0px;
}

.tracking-search-input {
  width: 50%;
  height: 80px;
  margin-top: 40px;
  background-color: #e2edf2;
  padding: 13px 0px;
  border-radius: 3rem;
  display: flex;
  justify-content: space-between;
  border: #e2edf2 solid 5px;
}

.tracking-search-section input {
  background-color: white;
  border: 1px solid rgb(223, 226, 230);
  height: 100%;
  padding: 0px 20px;
  border-radius: 2rem;
  outline: none;
  transition: 500ms;
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
}

.tracking-search-section button {
  height: 100%;
  border: 0;
  border-radius: 2rem;
  background-color: var(--info-color);
  color: white;
  outline: none;
  font-weight: 500;
  transition: 500ms;
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
}

.tracking-search-section input:hover,
.tracking-search-section input:focus {
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
  background-color: rgb(255, 255, 255);
  transition: 500ms;
}

.tracking-search-section button:hover,
.tracking-search-section button:focus {
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
  background-color: rgb(241, 244, 247);
  color: var(--info-color);
  transition: 500ms;
}

.status-card {
  padding-top: 20px;
}

.status-card::after {
  content: " \21B7";
  font-size: 40px;
  transform: rotateX(180deg);
  position: absolute;
  right: -10px;
  color: rgb(164, 174, 185);
}

.status-card p {
  margin: 0px;
}

.status-card .name {
  color: var(--info-color);
  font-weight: 700;
  letter-spacing: 1px;
}

.status-card .date {
  font-size: 18px;
  font-weight: bold;
  color: rgb(73, 76, 80);
}

.status-card .time {
  font-size: 16px;
  color: rgb(73, 76, 80);
}

.status-card .user {
  background-color: var(--info-color);
  padding: 3px 10px;
  color: white;
  margin-top: 5px;
  font-weight: 600;
  transition: 500ms;
  cursor: pointer;
  margin: 0px;
  display: block;
}

.status-card .user:hover {
  background-color: rgb(113, 135, 150);
  transition: 500ms;
}

.status-card-bottom {
  background-color: rgb(228, 233, 238);
  padding-bottom: 20px;
  padding-top: 10px;
  margin-top: 10px;
  border-bottom-left-radius: 3px;
  border-bottom-right-radius: 3px;
}

.status-card-bottom span {
  font-weight: 700;
  font-size: 12px;
  letter-spacing: 1px;
}

@media screen and (max-width: 768px) {
  .tracking-search-input {
    height: 120px;
    justify-content: center;
  }

  .tracking-search-input input {
    height: 45px;
  }

  .tracking-search-input button {
    margin-top: 10px;
    width: 40%;
  }
}
