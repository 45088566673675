.date_heading {
  margin: 0;
  color: black;
  font-size: 15px;
  font-weight: 500;
}

.graph_heading {
  margin: 0;
  color: black;
  font-size: 20px;
  font-weight: 700;
}

.order_head {
  width: 25px;
  transform: rotate(-90deg);
}
