.heading-content {
  padding-top: 50px;
  font-size: 40px;
  color: #bd0000;
  text-transform: uppercase;
  font-weight: 900;
}

.contact-paragraph {
  font-size: 20px;
  color: #707070;
}

.map-data {
  background-repeat: no-repeat;
  background-size: cover;
  background-position: top bottom;
  height: 400px;
}

.contact-card {
  background-color: white;
  min-height: 20rem;
  border: none;
  margin: 10px 0px;
  box-shadow: 3px 3px 4px 0px #0000002e;
}

/* .contact-card:hover{
    background-color: #f8f8f8;
} */

.cart-text p {
  font-size: 13px;
  margin-bottom: 10px;
}

.card-heading h5 {
  margin-top: 10px;
  font-size: 18px;
  color: #707070;
  font-weight: 500;
}

.card-heading p {
  font-size: 14px;
  color: #9a9a9a;
  font-weight: 400;
}

.contact-card-logo {
  background-color: #edf0f3;
  border-radius: 50%;
  min-width: 62px;
  height: 62px;
  margin-left: 0;
  margin-top: 0;
  box-shadow: 3px 3px 9px #c7c7c7;
  display: flex;
  align-items: center;
  justify-content: center;
}
