.service-item-section {
    padding-top: 60px;
    padding-bottom: 60px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.prohibited-image {
  width: 50%;
}

.prohibited-heading {
  font-weight: 900;
  color: #bd0000;
  text-align: center;
  padding: 20px 0px;
}

.items ul {
  list-style: none;
  font-size: 18px;
  font-weight: bold;
  color: #707070;
  margin: 0;
  padding: 0;
}

.items ul li:before {
  content: "✔";
  color: #bd0000;
  padding-right: 10px;
}
