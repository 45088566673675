.threesteps-section {
  padding-top: 50px;
  padding-bottom: 100px;
}

.section-title {
  text-align: center;
  padding-bottom: 30px;
  font-weight: 800;
  color: rgb(170, 170, 170);
}

.threestep-card {
  padding: 25px 25px;
  text-align: center;
  margin: 10px 0px;
  background-color: white;
  box-shadow: 2px 2px 20px rgba(0, 0, 0, 0.2);
  border-radius: 10px;
  color: rgb(175, 175, 175);
  min-height: 42rem;
  transition: 500ms;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.threestep-card h3 {
  margin-bottom: 10px;
  font-weight: 700;
}

.threestep-card h6 {
  margin: 0;
  line-height: 1.5rem;
  font-weight: 500;
}

.icon-circle {
  height: 120px;
  width: 120px;
  border-radius: 100%;
  background-color: rgba(209, 209, 209, 0.63);
  box-shadow: 1px 1px 5px rgba(71, 71, 71, 0.315);
  margin: 20px auto;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: 500ms;
}

.step-icon {
  fill: rgb(119, 119, 119);
  transition: 500ms;
}

.step-number-space {
  position: relative;
  height: 200px;
}

.step-number {
  position: absolute;
  color: rgba(241, 241, 241, 0.801);
  transform: translate(-32px, 102px);
  left: 0;
  bottom: 0;
  font-size: 15rem;
  font-weight: 900;
  margin: 0;
  text-align: left;
  padding: 0;
  transition: 500ms;
}

.step-click-btn {
  background-color: white;
  border: 0.12rem solid white;
  color: var(--red-color);
  font-weight: 700;
  letter-spacing: 1px;
  box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.445);
  padding: 10px 25px;
  border-radius: 5px;
  position: absolute;
  transition: 500ms;
  transform: translate(-50%, 600%);
  opacity: 0;
}

.step-click-btn:hover {
  border: 0.12rem solid white;
  background-color: transparent;
  color: white;
}

.threestep-card:hover {
  background-color: var(--red-color);
  transition: 500ms;
  color: white;
}

.threestep-card:hover .step-number {
  display: none;
}

.threestep-card:hover .icon-circle {
  background-color: rgb(143, 24, 24);
  transition: 500ms;
}

.threestep-card:hover .step-click-btn {
  transform: translate(-50%, 100%);
  opacity: 1;
  transition: 500ms;
}

.threestep-card:hover .step-icon {
  fill: white;
  transition: 500ms;
}

