.forgot-input{
    padding: 0 30px;
}

.forgot-heading {
  font-size: 27px;
  font-weight: 700;
  color: black;
}

.forgot-btn {
  background: linear-gradient(to right, #35c1e8, #0889ad);
  border: none;
  
}

.forgot-submit-txt {
    text-transform: uppercase;
    letter-spacing: 2px;
    font-weight: 600;
    margin: 20px 0px;
    color: #ffffff;
  }

